<template>
	<div>
		
		<div class="Top">
			
			<div class="OrderTypes">
				订单申诉/售后
			</div>
			
			<div class="OrderSearch">

				
				<span>
					<label>订单编号</label>
					<el-input size="mini" style="width: 200px;" v-model="Search.Id"></el-input>
				</span>
				
				<span>
					<label><em style="color: #FFFFFF;">|</em></label>
					<el-button size="mini" icon="el-icon-search" @click="StartSearching()">搜索订单</el-button>
				</span>
				
			</div>
			
		</div>
		
		<div class="TopTitle">
			<div class="C1">
				<span class="el-dropdown-link">
				  申诉信息
				</span>
			</div>
			<div class="C2">申诉类型</div>
			<div class="C2">申诉商品数</div>
			<div class="C3">退款金额</div>
			<div class="C4">订单状态</div>
			<div class="C7"></div>
			
		</div>

		<div class="Table" v-for="appeal in AppealList" :key="appeal.Id">
			
			<div class="Title">
				<ul class="Info">
					申诉编号{{appeal.Id}}
					<span style="margin-left: 10px;" v-if="3>4">{{appeal.StatusName}}</span>
				</ul>
				<ul class="OrderId">
					创建于 {{appeal.CreatedAt}}
				</ul>
			</div>
			
			<div class="Grid">
				
				<div class="C1">
					<div class="Left">
						<img :src="appeal.GoodsThumb" />
					</div>
					<div class="Right">
						{{appeal.GoodsTitle}}
					</div>
				</div>
				<div class="C2">{{appeal.AppealTypeName}}</div>
				<div class="C2">{{appeal.AppealNum}}</div>
				<div class="C3">{{appeal.AppealRefund}}</div>
				<div class="C4">{{appeal.StatusName}}</div>
				<div class="C7">
					<span>
						
						<el-button size="mini" type="primary" @click="$Jump('/my/seller/appeal/'+appeal.Id)">详情</el-button>
					</span>
				</div>
				
			</div>
			
			
		</div>
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="Total" :page-size="PageSize" :current-page="Page" @current-change="handleCurrentChange">
			</el-pagination>
			
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination} from 'element-ui'
	export default {
	  name: 'SellerAppealList',
	  props: {
	  },
	  data() {
	      return {
			  Search:{
				  Status:90,//订单状态
				  Id:'',//订单ID
			  },
			  Page:1,
			  PageSize:2,
			  Total:0,
			  AppealList:[],
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination
	  },
	  created() {
	  	this.StartSearching()
	  },
	  methods:{
		  handleCurrentChange(val){
		  		this.GetList(val)
		  },
		  StartSearching(){
			  this.GetList(this.Page)
			  this.GetCount()
		  },
		  GetList(_page){
		  
		  		let data = {
		  			Service:'Order',
		  			Class: 'Appeal',
		  			Action: 'List',
		  			Page:_page,
		  			PageSize:this.PageSize,
					IsShopAppeal:true, //是否只查询店铺内的订单
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
					
					if(res.Data.AppealList == null){
						this.AppealList = []
						return
					}
					this.AppealList = res.Data.AppealList
					this.Page = res.Data.CurrentPage
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  GetCount(){
		  
		  		let data = {
		  			Service:'Order',
		  			Class: 'Appeal',
		  			Action: 'Count',
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			
					this.Total = res.Data.Count
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
	  }
	}
</script>

<style scoped>
	.Top{
		display: flex;
		margin: 20px 20px 0px 20px;
		background-color: #FFFFFF;
		align-items: center;
		padding: 25px;
		
	}
	.Top .OrderTypes{
		flex: 1;
	}
	.OrderTypes span{
		margin-right: 15px;
		cursor: pointer;
	}
	.OrderTypes span.On{
		text-decoration: underline;
	}
	.Top .OrderSearch{
		display: flex;
	}
	.Top .OrderSearch span{
		margin-left: 10px;
		display: block;
	}
	.Top .OrderSearch span label{
		display: block;
		margin-bottom: 5px;
		font-size: 0.9rem;
		color: rgba(0,0,0,0.4);
	}
	
	.TopTitle{
		padding: 10px 25px;
		display: flex;
		color: #999999;
		font-size: 0.9rem;
		margin: 5px 20px 0px 20px;
	}
	
	.C1{
		flex: 1;
		display: flex;
		align-items: center;
	}
	.C1 .Left{
		width: 120px;
	}
	.C1 .Left img{
		width: 80px;
		height: 60px;
	}
	.C1 .Right{
		flex: 1;
	}
	.C1 .Right li{
		width: 100%;
		text-align: right;
		font-size: 0.9rem;
		color: rgba(0,0,0,0.3);
		margin-top: 10px;
	}
	.C2,.C3,.C4,.C5,.C6{
		width: 120px;
		text-align: center;
	}
	.C4{
		width: 200px;
		text-align: center;
	}
	.C7{
		width: 50px;
		text-align: center;
		margin-right: 10px;
	}
	.C7 span{
		padding: 5px 10px;
		cursor: pointer;
	}
	.C7 span:hover{
		color: rgba(228,0,0,1);
	}
	
	.Table{
		margin: 20px;
		padding: 20px;
		background-color: #FFFFFF;
	}
	.Table .Title{
		display: flex;
		margin-bottom: 15px;
		color: rgba(0,0,0,0.3);
		font-size: 0.9rem;
	}
	.Table .Title .Info{
		flex: 1;
	}
	.Table .Title .OrderId{
		width: 200px;
		text-align: right;
	}
	.Table .Grid{
		display: flex;
		padding: 10px 0px;
		border-top: 1px solid rgba(0,0,0,0.04);
		align-items: center;
	}
</style>
